<script setup lang="ts">
import type { IconClass } from '#core/config/ui'

withDefaults(defineProps<{
  name?: IconClass
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full'
  color?: keyof typeof colors
}>(), {
  name: 'i-heroicons-check-circle',
  size: 'md',
})

const sizes = {
  sm: 'size-2.5',
  md: 'size-4',
  lg: 'size-5',
  xl: 'size-6',
  full: 'min-size-4 size-full',
}

const colors = {
  primary: `  text-primary-300`,
  green: `    text-green-600`,
  yellow: `   text-yellow-700`,
  red: `      text-red-600`,
  gray: `     text-slate-600`,
}
</script>

<template>
  <i data-ui="UiIcon" class="shrink-0" :class="[name, sizes[size], color ? colors[color] : undefined]" />
</template>
